import { Link } from 'gatsby'
import React from 'react'
import Icon from './icons'
import { cn } from '../lib/helpers'

import styles from './header.module.css'

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <Link to="/">
        <div className={styles.logo}>
          <img alt="Rock Yoga" width="260" height="53" src="/images/logo.webp" />
        </div>
      </Link>

      <button
        aria-label="mobile menu"
        className={styles.toggleNavButton}
        onClick={showNav ? onHideNav : onShowNav}
      >
        <Icon symbol="hamburger" />
      </button>

      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li>
            <Link to="/classes/">Classes</Link>
          </li>

          <li>
            <Link to="/blog/">Blog</Link>
          </li>
          <li>
            <Link to="/about/">About</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.momoyoga.com/rock-yoga/">Book a Class</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
)

export default Header
