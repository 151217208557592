import React from 'react'
import Header from './header'
import Mandala from "../assets/yoga-flower.svg";
import favicon from '../../static/images/favicon.ico'
import Helmet from 'react-helmet'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <Helmet>
      <link rel="icon" href={favicon} />
    </Helmet>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
        <img alt="RYT 200 Hour Training" width="80" height="80" src="/images/RYT200.webp" />
          © {new Date().getFullYear()} - Rock Yoga
          <br />
          Namaste
        </div>
        <Mandala className="mandala" />
      </div>
    </footer>
  </>
)

export default Layout
